import React from "react";
import { useRefinementList } from "react-instantsearch";
import type { RefinementListProps } from "react-instantsearch";
import { Checkbox } from "~/components/ui/form/checkbox";
import { H4, Paragraph } from "~/components/ui/typography";
import { spTrackFilter } from "~/utils/tracking";
import { Icon } from "~/components/ui/icons";

import { type FacetingType } from "~/types/global-search";

const labelForFacetingType: Record<FacetingType, string> = {
	news: "Press releases & News",
	customerStory: "Customer stories",
	guide: "Tutorials & Guides",
	webinar: "Webinars & Videos",
	resource: "White papers & Ebooks",
	event: "Upcoming events",
	documentation: "Documentation",
	post: "Blog posts",
	service: "Services & Solutions",
};

export function RefinementList(props: RefinementListProps) {
	const { items, refine } = useRefinementList(props);
	const selectedItems = items
		.filter((item) => item.isRefined)
		.map((item) => item.value);

	const [activeFitlers, setActiveFilters] = React.useState(selectedItems);

	const [open, setOpen] = React.useState(false);

	function renderFilterList() {
		return (
			<ul className="ais-RefinementList-list mt-5 grid grid-cols-2 lg:mt-6 lg:grid-cols-1">
				{items
					.sort((a, b) => {
						const aLabel = labelForFacetingType[a.label as FacetingType];
						const bLabel = labelForFacetingType[b.label as FacetingType];

						return aLabel.localeCompare(bLabel);
					})
					.map((item) => (
						<li key={item.value}>
							<Checkbox
								id={`filter-${item.value}`}
								name={`${item.value}`}
								value={item.value}
								checked={item.isRefined}
								onChange={() => {
									const { value } = item;

									let updatedFilters = [];

									if (activeFitlers.includes(value)) {
										updatedFilters = activeFitlers.filter(
											(item) => item !== value
										);
									} else {
										// if filter is inactive, add it to activeFilters
										updatedFilters = [...activeFitlers, value];
									}

									spTrackFilter(updatedFilters);

									setActiveFilters(updatedFilters);

									refine(item.value);

									window?.scrollTo({
										top: 0,
										behavior: "instant" as ScrollBehavior,
									});
								}}
								className="mb-5 text-xs lg:text-sm"
							>
								{labelForFacetingType[item.label as FacetingType]} ({item.count}
								)
							</Checkbox>
						</li>
					))}
			</ul>
		);
	}

	function renderMobileList() {
		return (
			<>
				<button onClick={() => setOpen((open) => !open)}>
					<div className="flex items-center justify-center gap-3">
						<Paragraph fontWeight="font-semibold">Filter by</Paragraph>

						{open ? (
							<Icon
								name="chevron-up"
								color="arrow"
								className="h-[12px] w-[12px]"
							/>
						) : (
							<Icon
								name="chevron-down"
								color="arrow"
								className="h-[12px] w-[12px]"
							/>
						)}
					</div>
				</button>
				{open ? renderFilterList() : null}
			</>
		);
	}

	function renderDesktopList() {
		return (
			<>
				<H4 as="p">Filter by</H4>
				{renderFilterList()}
			</>
		);
	}

	return (
		<div className="ais-RefinementList mb-6 lg:mb-layout5">
			<div className="hidden lg:block">{renderDesktopList()}</div>
			<div className="block lg:hidden">{renderMobileList()}</div>
		</div>
	);
}
